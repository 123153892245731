import npm from '../../package.json';

export const environment = {
  production: false,
  type: 'development',
  encryptedKey: process.env.ENCRYPTED_KEY,
  version: npm.version,
  commonApi: 'http://35.154.255.206:8917/api/v1',
  preferredCountries: ['us', 'in'],
  logo: '/assets/images/map-india-dark.svg',
  title: 'Digital Post Card Admin Console'
};
